<template>
  <div class="transfer">
    <div class="transfer__left">
      <div class="transfer__header">
        <base-check-box
          class="transfer__checkbox transfer__checkbox_main"
          checkBoxColor="#ffffff"
          activeCheckBoxColor="#ffffff"
          v-model:startState="selectAllFrom"
          @update:startState="setSelectAll(selectAllFrom, 'from')"
        ></base-check-box>

        <h1 class="transfer__title">
          {{ favorite.title }}
        </h1>
      </div>

      <div v-if="leftLoad" class="transfer__main">
        <circle-c  class="transfer__loader"></circle-c>
      </div>
      <div v-else class="transfer__main">
        <div v-for="(item, index) in favorite.table" :key="index" class="transfer__item">
          <base-check-box
            class="transfer__checkbox transfer__checkbox_main"
            v-model:startState="from[item.id]"
          ></base-check-box>

          <p class="transfer__text">
            {{ item.group_name }}
          </p>
        </div>
      </div>
    </div>

    <div class="transfer__rigth">
      <div class="transfer__header">
        <base-check-box
          class="transfer__checkbox"
          checkBoxColor="#ffffff"
          activeCheckBoxColor="#ffffff"
          v-model:startState="selectAllTo"
          @update:startState="setSelectAll(selectAllTo, 'to')"
        ></base-check-box>

        <h1 class="transfer__title">
          {{ selected.title }}
        </h1>
      </div>

       <div v-if="rightLoad" class="transfer__main">
        <circle-c  class="transfer__loader"></circle-c>
      </div>
      <div v-else class="transfer__main">
        <div v-for="(item, index) in selected.table" :key="index" class="transfer__item">
          <base-check-box
            class="transfer__checkbox"
            v-model:startState="to[item.id]"
          ></base-check-box>

          <p class="transfer__text">
            {{ item.group_name }}
          </p>
        </div>
      </div>
    </div>

    <div class="transfer__menu">
      <button
        class="transfer__button"
        @click="moveSelectedGroup(favorite, selected,  'from', 'save', 'from', 'to')"
      >
        <img src="/icons/common/arrow-right.png" class="transfer__icon" />
      </button>

      <button
        class="transfer__button"
        @click="moveSelectedGroup(favorite, selected, 'from', 'delete', 'from', 'to')"
      >
        <img src="/icons/common/icond.png" class="transfer__icon" />
      </button>

      <button
        class="transfer__button"
        @click="moveSelectedGroup(selected, favorite, 'to', 'delete', 'to', 'from')"
      >
        <img src="/icons/common/icond.png" class="transfer__icon" />
      </button>
    </div>

    <close-icon class="close" @click="$emit('closeWindow')"></close-icon>
  </div>
</template>

<script>
import CloseIcon from '../atom/html-icon/symbol/CloseIcon.vue';
import RequestApi from '@/api/vk';
import userGroups from '@/api/userGroups';
import BaseCheckBox from '../atom/BaseCheckBox.vue';
import CircleC from '@/components/atom/loader/Circle.vue';

export default {
  components: {
    CloseIcon,
    BaseCheckBox,
    CircleC,
  },
  emits: ['closeWindow'],
  props: {
    method: {
      type: String,
      required: true,
    },

    groups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    calcCheckedGroups() {
      return '';
    },
  },
  data() {
    return {
      favorite: {},
      selected: {},

      leftLoad: false,
      rightLoad: false,

      selectAllFrom: false,
      selectAllTo: false,
      from: {},
      to: {},

      groupsApi: {},
    };
  },
  methods: {
    requestDataByGroups(group) {
      if (group) {
        const name = group.title;
        const listId = group.id;

        if (group.title === this.favorite.title) {
          this.leftLoad = true;
        } else {
          this.rightLoad = true;
        }

        const newClass = { [name]: new RequestApi(listId) };

        newClass[name].getGroupsJSON().then((resp) => {
          if (group.title === this.favorite.title) {
            // console.log('check resp', resp);
            this.favorite.table = resp;
            this.createGroupsTable(resp, 'from');
            this.leftLoad = false;
          } else {
            this.selected.table = resp;
            this.createGroupsTable(resp, 'to');
            this.rightLoad = false;
          }
        });

        // if (this.groupsApi[name] === undefined) {
        //   this.groupsApi[name] = newClass[name];
        // }
      }
    },

    createGroupsTable(groups, obj) {
      groups.forEach((el, ind) => {
        if (ind === 0) {
          this[obj][el.id] = true;
          return;
        }

        this[obj][el.id] = false;
      });

      // console.log('createGroupsTable', this[obj]);
    },
    setSelectAll(selectedAll, groupName) {
      Object.keys(this[groupName]).forEach((key) => {
        this[groupName][key] = selectedAll;
      });
    },

    moveSelectedGroup(from, to, curr, action = 'save', delFrom, addTo) {
      let resultGids = '';
      let deleteGids = '';
      Object.keys(this[curr]).forEach((key) => {
        if (this[curr][key]) {
          const groupWasAdd = this.checkGroupByAdd(key, to);
          if (action === 'delete') {
            deleteGids += `${key};`;
          }
          if (!groupWasAdd) {
            resultGids += `${key};`;
          }
        }
      });

      if (resultGids !== '') {
        this.addCurrentGroup({
          gid: resultGids,
          lid: to.id,
          prevGroup: from,
          currentGroup: to,
          addTo,
          action,
          flid: from.id,
          delFrom,
          deleteGids,
        });
      } else if (action === 'delete' && deleteGids !== '') {
        this.deleteCurrentGroup({
          gid: deleteGids,
          lid: from.id,
          currentGroup: from,
          delFrom,
        });
      }
    },

    addCurrentGroup({
      gid,
      lid,
      prevGroup,
      currentGroup,
      addTo,
      action,
      flid,
      delFrom,
      deleteGids,
    }) {
      // console.log('cals addCurrentGroup', gid, lid);
      userGroups.addGroupInList({ gid, lid }).then(() => {
        const arrGid = gid.split(';');

        arrGid.forEach((el) => {
          const group = this.findGroupByID(+el, prevGroup);
          // console.log('indidr', el);
          if (group === undefined || el === '') {
            return;
          }
          // console.log('currentGroup.table', addTo);
          currentGroup.table.push({ ...group });
          this[addTo][+el] = false;
        });

        // console.log('push addCurrentGroup', currentGroup.table);

        if (action === 'delete') {
          this.deleteCurrentGroup({
            gid: deleteGids,
            lid: flid,
            currentGroup: prevGroup,
            delFrom,
          });
        }
      });
    },

    deleteCurrentGroup({
      gid, lid, currentGroup, delFrom,
    }) {
      // console.log('cals deleteCurrentGroup', gid, lid);
      userGroups.deleteGroupFromList({ gid, lid }).then(() => {
        const arrGid = gid.split(';');
        arrGid.forEach((el) => {
          const group = this.findGroupIndexByID(el, currentGroup);

          if (+group === -1 || el === '') {
            return;
          }

          currentGroup.table.splice(group, 1);
          delete this[delFrom][el];
        });

        // console.log('delete deleteCurrentGroup', gid);
      });
    },

    findGroupByID(id, currentGroup) {
      // console.log('findGroupByID cals', currentGroup, id);
      return currentGroup.table.find((el) => +el.id === +id);
    },

    findGroupIndexByID(id, currentGroup) {
      // console.log('findGroupIndexByID cals', currentGroup, id);
      return currentGroup.table.findIndex((el) => +el.id === +id);
    },

    checkGroupByAdd(id, group) {
      return !!group.table.find((el) => +el.id === +id);
    },
  },
  mounted() {
    this.groups.forEach((el) => {
      if (el.title === 'Избранное') {
        this.favorite = el;
      } else {
        this.selected = el;
      }

      this.requestDataByGroups(el);
    });
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 500px;
  width: 500px;

  z-index: 100;

  position: absolute;
  top: 0;
  right: 0;

  background: #d8d8d8;

  &__loader {
    width: 50%;
    height: 50%;

    margin: auto;
  }

  // .transfer__left
  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    flex-basis: 50%;
    height: 100%;
    background: #f2f3f5;
    overflow: auto;
    overflow-x: hidden;

    border: 3px solid #03030325;
  }

  // .transfer__rigth
  &__rigth {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    flex-basis: 50%;
    height: 100%;
    background: #f2f3f5;
    overflow: auto;
    overflow-x: hidden;

    border: 3px solid #03030325;
  }

  // .transfer__menu
  &__menu {
    position: absolute;
    top: 70%;
    right: 45%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // .transfer__button
  &__button {
    width: 40px;
    height: 40px;
    border-radius: 10px;

    margin: 0 5px;
    border: none;
    cursor: pointer;

    &:nth-child(2) {
      transform: rotate(-90deg);

      border: 3px solid #000000;
    }

    &:nth-child(3) {
      transform: rotate(90deg);
      border: 3px solid #000000;
    }
  }

  // .transfer__icon
  &__icon {
    width: 100%;
    height: 100%;
  }

  &__checkbox {
    min-width: 18px;
    height: 18px;

    margin: 0 10px 0 10px;

    // &_main {
    //   border: 2px solid #f2f3f5;
    //   border-radius: 10px;
    // }
  }

  &__text {
    display: block;
    /* align-items: center; */
    /* justify-content: flex-start; */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;

    background: #45688e;
    color: #f2f3f5;

    margin: 0 0 10px 0;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  &__title {
    padding: 10px;
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;

  width: 20px;
  height: 20px;
}
</style>
