<template>
  <div class="confirm">
    <div class="confirm__back" @click="$emit('closer')"></div>

    <div class="confirm__main" @click="errorMsg = ''">
      <p class="confirm__text">Укажите id подтверждаемой группы</p>

      <input type="text" class="input" placeholder="" v-model="groupId" />
      <transition name="fader">
        <div v-if="checkedLink" class="confirm-container">
          <p class="confirm__text">Вставьте эту строку в поле {{ polName }}</p>

          <input type="text" class="input" placeholder="" v-model="checkedLink" readonly />

          <p class="confirm__text">После добавления уникальной строки подтвердите действие</p>
        </div>
      </transition>

      <transition name="fader">
        <div v-if="errorMsg" class="confirm__error" @click.stop>
          <input type="text" class="input red-color" placeholder="" v-model="errorMsg" readonly />
        </div>
      </transition>

      <div class="confirm__panel">
        <a
          href="#"
          class="confirm__button"
          :class="{ 'green-color': sucsessRequests }"
          @click.prevent="makeActionByBtnName(submittBtn)"
        >
          {{ submittBtn }}
        </a>

        <a
          v-if="!sucsessRequests"
          href="#"
          class="confirm__button"
          @click="makeActionByBtnName(cancleBtn)"
        >
          {{ cancleBtn }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import userGroups from '@/api/userGroups';

export default {
  props: {
    method: {
      type: String,
      required: true,
    },
  },
  emits: ['closer'],
  data: () => ({
    submittBtn: 'Сгенерировать ссылку',
    cancleBtn: 'Отмена',
    number: 4,
    groupId: '',
    checkedLink: '',
    polName: '',
    errorMsg: '',
    sucsessRequests: false,
  }),
  methods: {
    makeActionByBtnName(btnName) {
      switch (btnName) {
        case 'Сгенерировать ссылку':
          this.generateLink();
          break;
        case 'Подтвердить действие':
          this.confirmAddGroup();
          break;
        case 'Сбросить':
          this.resetGroupAdd();
          break;
        default:
          this.$emit('closer');
          break;
      }
    },

    generateLink() {
      const { userId } = this.$store.getters['auth/authParams'];
      userGroups.confirmGroup({ gid: this.groupId, uid: userId }).then((resp) => {
        if (resp.error) {
          this.errorMsg = resp.error;
          return;
        }
        this.checkedLink = resp.random_str;
        this.polName = resp.pole_name;
        this.submittBtn = 'Подтвердить действие';
        this.cancleBtn = 'Сбросить';
      }).catch((err) => {
        this.errorMsg = err;
      });
    },

    confirmAddGroup() {
      const { userId } = this.$store.getters['auth/authParams'];

      userGroups
        .submitAdminsGroup({ gid: this.groupId, uid: userId })
        .then((resp) => {
          if (resp.error) {
            this.errorMsg = resp.error;
          } else {
            this.sucsessRequests = true;
            setTimeout(() => this.$emit('closer'), 500);
          }
        })
        .catch((err) => {
          this.errorMsg = err;
        });
    },

    resetGroupAdd() {
      this.submittBtn = 'Сгенерировать ссылку';
      this.cancleBtn = 'Отмена';
      this.checkedLink = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    width: 110px;
    height: 40px;

    box-sizing: border-box;

    color: #000000;
    font-size: 20px;

    margin: 0 15px 0;

    background: #dbdcdd;
    border-radius: 10px;

    &:hover {
      background: #45688e7a;
    }

    &:nth-of-type(1) {
      width: 250px;
    }
  }

  &__text {
    font-size: 19px;
    padding: 0 15px;
    line-height: 20px;
    margin: 20px 20px 15px;
    align-self: flex-start;
  }

  &__back {
    background: #22282f3f;
    height: 100%;
    width: 100%;
  }

  &__main {
    width: 100%;
    max-width: 700px;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    top: 10%;

    background: #ffffff;
    padding: 40px 0 0;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 20px 0 30px;
  }
  &__error {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.input {
  display: flex;

  width: 80%;
  // height: 0px;
  height: 25px;

  margin: 0 10px;

  align-items: center;
  font-size: 16px;
  text-align: center;
  color: #000;

  &::placeholder {
    color: #8e8e8f;
  }

  &:focus {
    opacity: 1;
  }
}

.confirm-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}

.red-color {
  color: #a80707;
  border: 1px solid #a80707;
  outline: #a80707;
}
.green-color {
  background: #1e7c0b;
  &:hover {
    background: #1e7c0b;
  }
}

.fader-enter-active,
.fader-leave-active {
  transition: all 1s ease;
  height: auto;
  max-height: 500px;
  animation: bounce-in 1s;
}

.fader-enter-from,
.fader-leave-to {
  opacity: 0;
  height: 0%;
  max-height: 0px;
  animation: bounce-in 1s reverse;
}

@keyframes bounce-in {
  0% {
    height: 0%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}
</style>
