<template>
  <div class="confirm">
    <div class="confirm__back" @click="$emit('closeWindow')"></div>

    <div class="confirm__main">
      <div class="main__container">
        <img :src="group.photo_link" alt="" class="main__img" />

        <h1 class="">
          {{ group.group_name }}
        </h1>
      </div>

      <div class="main__container">
        <p class="main__label">Цена продажи:</p>

        <input type="text" placeholder="Укажите цену паблика" class="main__input" v-model="price" />

        <p class="main__curr">руб</p>
      </div>

      <div class="main__container">
        <p class="main__label">Прибыль с паблика:</p>

        <input
          type="text"
          placeholder="Укажите прибыль с паблика"
          class="main__input"
          v-model="profit"
        />

        <p class="main__curr">руб/мес</p>
      </div>

      <div class="main__container">
        <p class="main__label">Способ раскрутки:</p>

        <input type="text" placeholder="" class="main__input" v-model="prom" />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__container">
        <p class="main__label">Источник расходов:</p>

        <input
          type="text"
          placeholder=""
          class="main__input"
          v-model="srsExp"
        />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__container">
        <p class="main__label">Источник доходов:</p>

        <input
          type="text"
          placeholder=""
          class="main__input"
          v-model="srsProf"
        />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__container">
        <p class="main__label">Описание контента:</p>

        <input
          type="text"
          placeholder=""
          class="main__input"
          v-model="content"
        />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__container">
        <p class="main__label">Способ поддержки группы:</p>

        <input
          type="text"
          placeholder=""
          class="main__input"
          v-model="support"
        />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__container">
        <p class="main__label">Передача группы при продаже:</p>

        <input
          type="text"
          placeholder=""
          class="main__input"
          v-model="ggroup"
        />

        <!-- <p class="main__curr">руб</p> -->
      </div>

      <div class="main__block">
        <p class="main__desc">Описание паблика:</p>

        <textarea
          type="text"
          placeholder="Общее описание паблика"
          class="main__area"
          v-model="desc"
          rows="10"
          cols="33"
        />
      </div>

      <div class="main__container">
        <base-button class="main__button" @click="addGroupBySale">Выставить на продажу</base-button>

        <base-button class="main__button" @click="$emit('closeWindow')">Отмена</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';
import PublicSale from '@/api/publicSale';

export default {
  components: {
    BaseButton,
  },
  props: {
    group: {
      type: String,
      required: true,
    },
  },
  emits: ['closeWindow'],
  data: () => ({
    price: '',
    desc: '',
    profit: '',
    prom: '', // способ раскрутки
    srsExp: '', // источник расходов
    srsProf: '', // источник доходов
    content: '', // описание контента
    support: '', // способ поддержки группы
    ggroup: '', // передача группы при продаже

    userId: '',
    publicSale: {},
  }),
  methods: {
    addGroupBySale() {
      const formData = {
        gid: this.group.id, // — айди группы
        uid: this.userId, // — айди админа (пользователя)
        price: this.price, // — цена продажи
        desc: this.desc, // — описание предпродажное
        profit: this.profit, // — доход
        prom: this.prom, // способ раскрутки
        srs_exp: this.srsExp, // источник расходов
        srs_prof: this.srsProf, // источник доходов
        content: this.content, // описание контента
        support: this.support, // способ поддержки группы
        ggroup: this.ggroup, // передача группы при продаже
      };

      this.publicSale.addGroupBySale(formData).then((resp) => {
        if (resp.message === 'access') {
          const updateOnCloseSellGroup = true;
          this.$emit('closeWindow', updateOnCloseSellGroup);
        }
      });
    },
  },
  mounted() {
    this.userId = this.$store.getters['auth/authParams'].userId;
    this.publicSale = new PublicSale();
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  &__num {
    margin: 0 0 0 10px;
  }

  &__text {
    font-size: 19px;
    padding: 0 15px;
    line-height: 20px;
    margin: 0 20px 25px;
  }

  &__back {
    background: #22282f3f;
    height: 100%;
    width: 100%;
  }

  &__main {
    min-width: 450px;
    max-width: 670px;
    min-height: 200px;

    width: 50%;
    height: auto;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    top: 10%;

    background: #ffffff;
    padding: 40px 40px 28px;
    box-sizing: border-box;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto 0 30px;
  }
}

.main {
  // .main__container
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 20px 0 0;

    &:last-child {
      justify-content: center;
    }
  }

  // .main__label
  &__label {
    flex-basis: 30%;
    text-align: start;
  }
  &__desc {
    margin: 0 auto 10px 0;
  }

  // .main__text
  &__text {
  }

  // .main__button
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 5px;

    text-decoration: none;

    min-width: 150px;
    width: auto;
    height: 40px;

    box-sizing: border-box;

    color: #000000;
    font-size: 20px;

    margin: 0 15px 0;

    background: #dbdcdd;
    border-radius: 10px;

    font-size: 16px;

    &:hover {
      background: #45688e7a;
    }
  }

  &__input {
    font-size: 16px;
    padding: 0 5px;
    width: 50%;
    height: 40px;
    box-sizing: border-box;
    outline: none;
    margin: 0 5px 0 0;
  }

  &__img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin: 0 80px 0 0;
  }

  &__curr {
  }

  &__area {
    resize: none;
    width: 100%;
    font-size: 16px;
    padding: 5px;
  }

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 35px 0 0;
  }
}
</style>
