<template>
  <div class="popup">
    <div class="popup__back"></div>

    <div class="popup__main">
      <p class="popup__text">Введите имя шаблона</p>

      <div class="popup__text">
        <input
          ref="input-popup"
          type="text"
          class="input"
          v-model="nameTitle"
          @click.prevent
          @keyup.enter="$emit('success', nameTitle)"
        />
      </div>

      <div class="popup__panel">
        <a href="#" class="popup__button" @click.prevent="$emit('success', nameTitle)">
          {{ okBtn }}
        </a>

        <a href="#" class="popup__button" @click="$emit('close')"> Отмена </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['success', 'close'],
  //   props: {
  //     method: {
  //       type: String,
  //       required: true,
  //     },
  //   },
  data: () => ({
    okBtn: 'Создать',
    nameTitle: 'Новый шаблон',
  }),
  methods: {},
  mounted() {
    this.$refs['input-popup'].select();
  },
};
</script>

<style lang="scss" scoped>

.popup {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  z-index: 100;

  position: absolute;
  top: 0;
  left: 0;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;

    width: 150px;
    height: 40px;

    box-sizing: border-box;

    color: #000000;
    font-size: 20px;

    margin: 0 15px 0;

    background: #dbdcdd;
    border-radius: 10px;

    &:hover {
      background: #45688e7a;
    }
  }

  &__num {
    margin: 0 0 0 10px;
  }

  &__text {
    font-size: 19px;
    padding: 0 15px;
    line-height: 20px;
    margin: 0 20px 25px;
  }

  &__back {
    background: #22282f3f;
    height: 100%;
    width: 100%;
  }

  &__main {
    width: 450px;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    position: absolute;
    top: 10%;

    background: #ffffff;
    padding: 40px 0 0;
  }

  &__panel {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto 0 30px;
  }
}

.input {
  width: 100%;
  height: 32px;
  margin: 0 0 0 10px;

  border: none;
  cursor: default;

  font-size: 20px;
  padding: 0 5px;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  outline: none;

  background: #c5c5c500;
}

.red-color {
  background: #a80707;
  &:hover {
    background: #ff0000;
  }
}
.green-color {
  background: #1e7c0b;
  &:hover {
    background: #1e7c0b;
  }
}
</style>
