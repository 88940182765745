<template>
  <div
    class="title-tb"
    :class="{ 'wrap-start': !showMainMenu || !isArrow || blockMenu }"
    :style="{ background: checkGroup()? '#27a20e70' : ''}"
    @click="calsAction('toogleTable', itemPosition)"
  >
    <div class="title-tb__input-panel">
      <arrow-icon
        v-if="isArrow"
        ref="arrow"
        :style="{ transform: 'rotate(' + rotateArrow + 'deg)' }"
        class="title-tb__arrow"
      ></arrow-icon>

      <base-check-box
        v-else-if="list.type !== 'my' && list.type !== 'favorite'"
        class="title-tb__checkbox"
        @update:startState="calsAction('updateList', itemPosition)"
        @click.stop
      />

      <div ref="target" class="title-tb__input">
        <slot ref="inputik"> </slot>
      </div>

      <button
        v-if="isArrow"
        :style="{ visibility: showSubmit ? 'visible' : 'hidden' }"
        class="title-tb__button"
        @click.stop="calsAction('submit', itemPosition)"
      >
        <img src="/icons/common/submit.svg" class="title-tb__icon" />
      </button>
    </div>

    <div v-if="showMainMenu && isArrow && !blockMenu" class="title-tb__menu">
      <button
        v-if="list.type !== 'my' && list.type !== 'favorite'"
        class="title-tb__button"
        @click.stop="calsAction('edit', itemPosition)"
      >
        <img src="/icons/common/edit.svg" class="title-tb__icon" />

        <span class="title-tb__button_text"> Изменить имя </span>
      </button>

      <button
        :class="{ 'max-width': list.type === 'my' }"
        class="title-tb__button"
        @click.stop="calsAction('select', itemPosition)"
      >
        <img :src="icon" class="title-tb__icon" />

        <span :class="{ 'max-width_text': list.type === 'my' }" class="title-tb__button_text">
          {{ changes }}
        </span>
      </button>

      <button
        v-if="list.type !== 'my'"
        class="title-tb__button"
        @click.stop="calsAction('delete', itemPosition)"
      >
        <img src="/icons/common/delete.svg" class="title-tb__icon" />

        <span class="title-tb__button_text"> {{ deleteBtn }} </span>
      </button>
    </div>

    <div v-else-if="isArrow && !blockMenu" class="title-tb__sub-menu">
      <button class="title-tb__button" @click.stop="calsSubAction('selectClose', itemPosition)">
        <img src="/icons/common/selk.png" class="title-tb__icon" />

        <span class="title-tb__button_text"> Вернуться </span>
      </button>

      <button
        v-if="list.type !== 'my'"
        class="title-tb__button"
        @click.stop="calsSubAction('deleteGroup', itemPosition)"
      >
        <img src="/icons/common/gdel.png" class="title-tb__icon" />

        <span class="title-tb__button_text"> Удалить группы </span>
      </button>
    </div>
  </div>
</template>

<script>
import ArrowIcon from '@/components/atom/html-icon/arrow/DropDownArrow.vue';
import BaseCheckBox from '../atom/BaseCheckBox.vue';

export default {
  components: {
    ArrowIcon,
    BaseCheckBox,
  },
  props: {
    itemPosition: {
      type: Number,
      default: 0,
    },

    isArrow: {
      type: Boolean,
      default: true,
    },

    list: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  emits: ['calsAction'],
  computed: {},
  watch: {
    isArrow(to) {
      if (!to && this.showSubmit) {
        this.toogleShowSubmit(false);
      }
    },
  },
  data() {
    return {
      showSubmit: false,
      rotateArrow: -90,
      showMainMenu: true,
      blockMenu: false,
      changes: 'Изменить группы',
      deleteBtn: 'Удалить шаблон',
      icon: '/icons/common/sel.png',
      // showContainer: false,
    };
  },
  methods: {
    hello() {
      // console.log('hello');
    },
    checkGroup() {
      return !this.isArrow && this.list.type !== 'my' && this.list.type !== 'favorite';
    },
    calsAction(action, value) {
      if (action === 'toogleTable' && this.$refs.arrow !== null) {
        this.rotateArrow = this.rotateArrow !== 0 ? 0 : -90;
      }

      if (action === 'edit') {
        this.showSubmit = true;
      }

      if (action === 'submit') {
        // console.log('action === submit');
        this.showSubmit = false;
      }

      if (action === 'select') {
        this.showMainMenu = false;
      }
      // console.log('watch opa');
      this.$emit('calsAction', { action, value });
    },

    toogleShowSubmit(newSubmit) {
      this.showSubmit = newSubmit;
      // console.log('toogleShowSubmit cals');
      this.calsAction('submit', this.itemPosition);
    },

    calsSubAction(action, value) {
      // console.log('watch opa');
      this.showMainMenu = true;
      this.$emit('calsAction', { action, value });
    },
  },

  mounted() {
    if (this.list.type === 'my') {
      this.changes = 'Выставить/снять с продажи';
      this.icon = '/icons/common/sale.svg';
    }
  },
};
</script>

<style lang="scss" scoped>
.title-tb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  // .title__input-panel
  &__input-panel {
    display: flex;
    align-items: center;
    // justify-content: center;
    width: 50%;
    min-width: 25rem;
    height: 100%;
  }

  // .title__inpu

  // .title__menu
  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 1;
  }
  @media (hover: hover) {
    &__button {
      display: flex;
      justify-content: flex-start;

      width: 32px;
      height: 32px;

      margin: 0 5px;
      border: none;
      border-radius: 10px;
      cursor: pointer;
      transition: width 1s;

      &:hover {
        width: 180px;
      }

      &_text {
        display: block;
        height: 100%;

        visibility: hidden;
        opacity: 0;
        transition: all 1s;
        width: 0px;

        line-height: 22px;
        margin: 0 0 0 10px;
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover .title-tb__button_text {
        visibility: visible;
        opacity: 1;
        width: 150px;
      }

      &:hover .max-width_text {
        visibility: visible;
        opacity: 1;
        width: 250px;
      }
    }
  }

  &__sub-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 1;
  }

  &__button {
    display: flex;
    justify-content: flex-start;

    width: 32px;
    height: 32px;

    margin: 0 5px;
    border: none;
    cursor: pointer;
    transition: width 1s;

    // &:hover {
    //   width: 200px;
    // }

    // &_text {
    //   display: block;
    //   height: 100%;

    //   visibility: hidden;
    //   opacity: 0;
    //   transition: all 1s;
    //   width: 0px;

    //   line-height: 22px;
    //   margin: 0 0 0 10px;
    //   font-size: 16px;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }

    // &:hover .title-tb__button_text {
    //   visibility: visible;
    //   opacity: 1;
    //   width: 150px;
    // }
  }

  // .title__icon
  &__icon {
    width: 32px;
    height: 100%;
  }

  &__arrow {
    width: 25px;
    height: 25px;

    margin: 0 0 0 5px;

    transform: rotate(-90deg);
  }

  &__checkbox {
    width: 20px;
    height: 20px;

    margin: 0 0 0 5px;
  }
}

.wrap-start {
  justify-content: flex-start;
}

.fader-enter-active,
.fader-leave-active {
  transition: all 2s ease;
  // opacity: 1;
  width: 200px;
}

.fader-enter-from,
.fader-leave-to {
  // opacity: 0;
  width: 32px;
}

.max-width {
  &:hover {
    width: 270px;
  }

  &_text {
    width: 250px;
  }
}
</style>
