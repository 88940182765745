<template>
  <div class="aside-menu">
    <aside-control class="control-menu">
      <template v-slot:title> Меню пользователя </template>

      <template v-slot:default>
        <a
          v-for="(button, index) in menuButtons"
          :key="index"
          href="#"
          class="button"
          @click.prevent="setAction(button)"
          >
          {{ button.name }}
        </a>
      </template>
    </aside-control>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AsideControl from '@/components/common/teamplates/ToogleMenu.vue';

export default {
  components: {
    AsideControl,
  },
  emits: ['action'],
  computed: mapGetters('vkRequests', ['getGroupCount']),

  data: () => ({
    showMenu: true,
    showFilter: true,

    menuButtons: [
      { name: 'Перенести группы', action: 'transfer' },

      { name: 'Подтвердить группу', action: 'check' },

      { name: 'Выставить/снять группу с продажи', action: 'sell' },
    ],
  }),
  methods: {
    setAction(button) {
      this.$emit('action', button.action);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.static-info {
  color: #45688e;
  font-weight: 700;
  font-size: 18px;

  margin: 18px 0 20px 0;
}
.control-menu {
  margin: 0 0 20px 0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: aliceblue;

  border-radius: 10px;
  background: #45688e;

  margin: 15px 10px;
}
</style>
