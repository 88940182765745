<template>
  <div ref="communites" class="communites">
    <div
      ref="list"
      @dragstart="drag"
      @dragend="drop"
      @dragover="dragover"
      class="contenter teamplate"
    >
      <table-teamplate
        :ref="'tmper' + index"
        v-for="(tmp, index) in getGroupList"
        :key="tmp.id"
        :showContainer="tmp.isOpen"
        :draggable="true"
        @dragover="dragElement('tmper' + index)"
        class="item-list"
      >
        <template #title>
          <table-teamplate-title
            :isArrow="isArrow"
            :itemPosition="index"
            :list="tmp"
            @calsAction="calsAction"
          >
            <div class="contain" :draggable="false">
              <input
                :ref="'tmp' + index"
                type="text"
                class="title__input"
                v-model="tmp.title"
                :readonly="tmp.readonly"
                :draggable="false"
                @click.prevent
                onmousedown="return false"
              />
            </div>
          </table-teamplate-title>
        </template>

        <template #table>
          <table-data-user
            :ref="tmp.title + index"
            v-if="tmp.isOpen"
            :table="tmp.table"
            :draggable="false"
            :showAnimation="tmp.showAnimation"
            :isMainGroup="tmp.title === 'Мои группы'"
            :indexPosition="getClassInstanceList[tmp.title]?.getPaginstionState() ?? 0"
            :tableName="tmp.title || ''"
            :isSelectShow="tmp.isSelectShow"
            :isActionByUpdate="tmp.title === 'Мои группы'"
            @toogleInfoPage="toogleInfoPage"
            @updateTable="updateTable"
            @sellGroup="setGroupForSell"
            @dellGroup="prepareGroupForDelete"
          ></table-data-user>
        </template>
      </table-teamplate>

      <button class="button" @click="openCreateListWindow">
        Создать новый шаблон <img src="/icons/common/add-icon.svg" class="button__icon" />
      </button>

      <transfer-group
        v-if="showTransferMenu"
        :groups="groups"
        @closeWindow="showTransferMenu = !showTransferMenu"
      ></transfer-group>
    </div>

    <aside-menu
      class="settings"
      :class="{ show: getSettingsState }"
      @action="checkList"
    ></aside-menu>

    <teleport to="body">
      <transition name="fade">
        <group-statistic
          v-if="isOpenGroupInformation"
          :groupInfo="groupInfo"
          :tableName="tableName"
          :groupListPosition="groupListPosition"
          @closeWindow="toogleInfoPage"
          class="group-statistic"
        ></group-statistic>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <list-name-popup
          v-if="showListNameWindow"
          @success="createNewTeamplate"
          @close="showListNameWindow = false"
          class="group-statistic"
        ></list-name-popup>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <popup-window
          v-if="showConfirmWindow"
          @ok="actionByConfirm"
          @close="showConfirmWindow = false"
          class="group-statistic"
        >
          <template #title> Вы уверены что хотите удалить шаблон?? </template>
        </popup-window>
      </transition>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <sell-group-modal
          v-if="showSellGroupModal"
          :group="currentSaleGroup"
          @closeWindow="updateSellGroupOnClose"
        >
        </sell-group-modal>
      </transition>
    </teleport>

    <teleport to="body">
      <popup-window
        v-if="showConfirmForDel"
        @ok="deleteGroupByConfirm"
        @close="showConfirmForDel = false"
        class="group-statistic"
      >
        <template #title> Снять группу с продажи? </template>
      </popup-window>
    </teleport>

    <teleport to="body">
      <transition name="fade">
        <confirm-page
          v-if="showConfirmPage"
          @closer="showConfirmPage = false"
          class="group-statistic"
        >
        </confirm-page>
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import GroupStatistic from '@/components/user-page/GroupStatistic.vue';
import GroupStatistic from '@/components/common/GroupStatistic.vue';
import AsideMenu from '@/components/user-page/AsideMenu.vue';
import TableTeamplate from '../../components/user-page/TableTeamplate.vue';
import TableTeamplateTitle from '../../components/user-page/TableTeamplateTitle.vue';
import ListNamePopup from '@/components/user-page/ListNamePopup.vue';
import PopupWindow from '@/components/common/PopupWindow.vue';
import TableDataUser from '../../components/user-page/DataUserTable.vue';
import RequestApi from '@/api/vk';
import TransferGroup from '../../components/user-page/TransferGroup.vue';
import userGroups from '@/api/userGroups';
import SellGroupModal from '@/components/user-page/SellGroupModal.vue';
import PublicSale from '@/api/publicSale';
import ConfirmPage from '@/components/user-page/ConfirmPage.vue';

export default {
  components: {
    GroupStatistic,
    AsideMenu,
    TableTeamplate,
    TableTeamplateTitle,
    ListNamePopup,
    PopupWindow,
    TableDataUser,
    TransferGroup,
    SellGroupModal,
    ConfirmPage,
  },
  data() {
    return {
      tasksListElement: '',
      taskElements: '',
      currentElement: '',
      teamplates: [],
      enableEye: false,
      tableName: '',
      groupListPosition: '',

      loadCount: false,
      show: false,
      addClassToPagination: false,
      delbuf: '',
      groupInfo: {},

      searchText: '',
      rotate: 0,
      listShow: null,
      selectedRows: [],
      showCatalogs: true,
      options: {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      },
      target: null,
      observer: null,

      isOpenGroupInformation: false,
      // hash map
      names: {
        Подписчики: 'sub',
        Посетители: 'vis',
        Прирост: 'grow',
        Охват: 'cov',
      },
      showListNameWindow: false,
      showConfirmWindow: false,
      showConfirmPage: false,
      valueConfirmWindow: -1,

      classTables: {},
      showTransferMenu: false,
      showTransfer: false,

      currentSaleGroup: {},
      showSellGroupModal: false,

      isArrow: true,
      groups: [],

      showConfirmForDel: false,
      deletedGroup: {},
      publicSale: {},
    };
  },
  computed: {
    ...mapGetters('vkConfiguration', ['getSettingsState']),
    ...mapGetters('adminRequest', ['checkLoginState', 'isUpdateVK']),
    ...mapGetters('userPanel', ['getGroupList']),
    ...mapGetters('pagination', ['getClassInstanceList']),
  },

  methods: {
    ...mapActions('vkRequests', [
      'requestCommunities',
      'requestByDate',
      'requestByType',
      'requestByStatus',
      'requestAllStaticParams',
      'requestCategory',
      'requestByVerification',
      'requestBySort',
      'requestBySearch',
      'requestPage',
      'requestPagePagination',
      'cancleRequest',
    ]),
    ...mapActions('pagination', ['addNewInstance']),
    ...mapMutations('vkRequests', [
      'setCurrentDate',
      'setLoadPage',
      'setChangeAnotherPage',
      'resetJSON',
      'setSearchInput',
      'resetAllParams',
      'setFiltredParametr',
      'setAdminsId',
    ]),
    ...mapMutations('adminRequest', ['dropVKUpdate']),
    ...mapMutations('routerControl', ['setCurrentActivePage']),
    ...mapMutations('userPanel', ['resetGroups']),

    dragElement(value) {
      this.currentElement = this.$refs[value].$el;
    },

    getNextElement(cursorPosition, currentElement) {
      const currentElementCoord = currentElement.getBoundingClientRect();
      const currentElementCenter = currentElementCoord.y + currentElementCoord.height / 2;

      const { nextElementSibling } = currentElement;
      const nextEl = cursorPosition < currentElementCenter ? currentElement : nextElementSibling;

      return nextEl;
    },

    dragover(evt) {
      evt.preventDefault();

      const activeElement = this.tasksListElement.querySelector('.selected');
      // const currentElement = evt.target;
      const isMoveable = activeElement !== this.currentElement
        && this.currentElement.classList.contains('item-list');

      // console.log('activeElement', evt);
      if (!isMoveable) {
        return;
      }

      const nextElement = this.getNextElement(evt.clientY, this.currentElement);

      if (
        (nextElement && activeElement === nextElement.previousElementSibling)
        || activeElement === nextElement
      ) {
        return;
      }

      this.tasksListElement.insertBefore(activeElement, nextElement);
    },

    drag(ev) {
      ev.target.classList.add('selected');
    },

    drop(ev) {
      ev.target.classList.remove('selected');
    },

    openCreateListWindow() {
      this.showListNameWindow = true;
    },

    createNewTeamplate(titleName) {
      this.showListNameWindow = false;

      const newList = {
        id: this.getGroupList.length,
        title: titleName,
        isOpen: false,
        readonly: true,
      };

      this.$store.dispatch('userPanel/create', newList);
    },

    async calsAction({ action, value }) {
      if (action === 'toogleTable') {
        // console.log('cals toogleTable');
        const { isOpen } = this.getGroupList[value];
        this.getGroupList[value].isOpen = !isOpen;

        if (this.getGroupList[value].length !== 0) {
          const name = this.getGroupList[value].title ?? 'lol';
          const listId = this.getGroupList[value].id;

          if (this.getClassInstanceList[name] === undefined) {
            const newClass = { [name]: new RequestApi(listId) };
            this.addNewInstance(newClass);

            this.getGroupList[value].showAnimation = true;
            await newClass[name].requestAuditory();
            // console.log('after start requestAuditory in calsAction and newClass start');
            newClass[name]
              .getGroupsJSON()
              .then((resp) => {
                this.getGroupList[value].table = resp;
                this.getGroupList[value].showAnimation = false;
              })
              .catch(() => {
                this.getGroupList[value].showAnimation = false;
              });
          }
        }
        // console.log('cals toogleTable this.getGroupList', this.getGroupList);
        return;
      }

      if (action === 'submit') {
        this.$store
          .dispatch('userPanel/update', { index: value })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });

        const updateArr = { index: value, param: 'readonly', state: true };
        this.$store.dispatch('userPanel/updateParam', updateArr);

        this.$refs[`tmp${value}`].value += ' ';
        this.$refs[`tmp${value}`].value = this.$refs[`tmp${value}`].value.slice(0, -1);
        this.$refs[`tmp${value}`].blur();
        this.$refs[`tmp${value}`].onmousedown = () => false;
        this.$refs[`tmp${value}`].onclick = null;

        return;
      }

      if (action === 'edit') {
        // console.log('before focus', this.teamplates[value]);

        const updateArr = { index: value, param: 'readonly', state: false };
        this.$store.dispatch('userPanel/updateParam', updateArr);

        this.$refs[`tmp${value}`].onmousedown = () => true;
        this.$refs[`tmp${value}`].onclick = (event) => {
          if (event.stopPropagation) {
            event.stopPropagation();
          } else if (window.event) {
            window.event.cancelBubble = true;
          }
        }; /*  */
        // this.$refs[`tmp${value}`].style['pointer-events'] = 'auto';
        this.$refs[`tmp${value}`].focus();
        this.$refs[`tmp${value}`].select();
        return;
      }

      if (action === 'select' || action === 'selectClose') {
        this.getGroupList[value].isSelectShow = !this.getGroupList[value].isSelectShow;
        return;
      }

      if (action === 'deleteGroup') {
        const { title, id: lid } = this.getGroupList[value];
        const delereGroups = this.$refs[`${title}${value}`].getCheckBoxes();

        let deleteGids = '';
        delereGroups.forEach((el) => {
          deleteGids += `${el.id};`;
        });

        userGroups.deleteGroupFromList({ gid: deleteGids, lid }).then(() => {
          const arrGid = deleteGids.split(';');
          arrGid.forEach((el) => {
            const group = this.findGroupIndexByID(el, this.getGroupList[value]);

            if (+group === -1 || el === '') {
              return;
            }

            this.getGroupList[value].table.splice(group, 1);
            // delete this[delFrom][el];
          });
        });

        this.getGroupList[value].isSelectShow = !this.getGroupList[value].isSelectShow;
        return;
      }

      if (action === 'updateList') {
        this.toogleTransferMenu(value);

        this.checkList('transfer');
        return;
      }
      // console.log('this.getGroupList[value]', this.getGroupList[value]);
      if (action === 'delete' && this.getGroupList[value].type !== 'common') {
        alert('Вы не можете удалить данную группу');
        return;
      }
      this.showConfirmWindow = true;
      this.valueConfirmWindow = value;
    },

    findGroupIndexByID(id, currentGroup) {
      return currentGroup.table.findIndex((el) => +el.id === +id);
    },

    toogleTransferMenu(groupIndex) {
      // console.log('cals toogleTransferMenu');
      const isShow = this.passToTransferArrayData(groupIndex);
      if (!isShow) {
        return;
      }

      this.showTransferMenu = !this.showTransferMenu;
      // console.log(this.getGroupList, groupIndex);
    },

    passToTransferArrayData(groupIndex) {
      const selectedGroup = this.getGroupList[groupIndex];
      const findFavorites = this.findGroupByName(this.getGroupList, 'Избранное');

      // TODO add check by admins group
      if (selectedGroup.type !== 'common') {
        // selectedGroup.title === 'Мои группы'
        alert('Выберете иную группу');
        return false;
      }

      this.groups.push(this.getGroupList[groupIndex]);

      if (findFavorites) {
        this.groups.push(findFavorites);
      }

      return true;
    },

    findGroupByName(groups, groupName) {
      const result = groups.find((el) => el.title === groupName);
      // console.log('findGroupByName', result);

      return result;
    },

    setGroupForSell(item = null) {
      this.showSellGroupModal = !this.showSellGroupModal;
      if (item !== null) {
        this.currentSaleGroup = item;
      }
    },

    updateSellGroupOnClose(isUpdated) {
      if (isUpdated) {
        this.currentSaleGroup.isSale = !this.currentSaleGroup.isSale;
      }
      this.showSellGroupModal = !this.showSellGroupModal;
    },

    prepareGroupForDelete(item = null) {
      if (item !== null) {
        this.showConfirmForDel = true;
        this.deletedGroup = item;
        // console.log('prepareGroupForDelete', item);
      }
    },
    deleteGroupByConfirm() {
      this.publicSale
        .deleteGroupBySale({
          gid: this.deletedGroup.id,
          uid: this.$store.getters['auth/authParams'].userId,
        })
        .then((resp) => {
          // console.log('deleteGroupByConfirm', resp);
          if (resp.message === 'access') {
            this.showConfirmForDel = false;
            this.deletedGroup.isSale = false;
          }
        })
        .catch(() => {
          alert('CAN NOT DELETE GROUP');
        });
    },

    checkList(action = '') {
      if (action === 'check') {
        this.showConfirmPage = !this.showConfirmPage;
      }

      if (action === 'transfer') {
        this.isArrow = !this.isArrow;
      }

      if (action === 'sell') {
        const index = this.findGroupIndexByName('Мои группы', this.getGroupList);

        if (index === -1) {
          return;
        }

        this.getGroupList[index].isSelectShow = !this.getGroupList[index].isSelectShow;
      }

      if (action === 'delete') {
        // console.log('delete group');
      }
    },

    findGroupIndexByName(name, currentGroup) {
      // console.log('sell group', name, currentGroup);
      return currentGroup.findIndex((el) => el.list_name === name);
    },

    actionByConfirm(confirmState) {
      if (!confirmState) {
        this.valueConfirmWindow = -1;
        return;
      }

      this.showConfirmWindow = false;

      this.$store
        .dispatch('userPanel/delete', this.valueConfirmWindow)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.valueConfirmWindow = -1;
        });
    },

    toogleInfoPage({
      groupInfo, isShow, tableName, groupListPosition,
    }) {
      if (!isShow) {
        this.groupInfo = {};
        this.tableName = '';
        this.groupListPosition = '';
      } else {
        this.groupInfo = groupInfo;
        this.tableName = tableName;
        this.groupListPosition = groupListPosition;
      }

      this.isOpenGroupInformation = isShow;
    },

    // TODO check for dell
    calcWidth(item) {
      // console.log(item);
      const width = +item.verified === 1 || +item.closed === 1 ? 20 : 0;
      return `calc(100% - ${width}px)`;
    },
    // not requests

    setArrow(item) {
      return +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    callFromFilterRequest(groupName, settingName) {
      switch (true) {
        case groupName === 'period':
          this.requestByDate(settingName);
          break;
        case groupName === 'type':
          this.requestByType(settingName);

          if (settingName === 'group') {
            this.showCatalogs = false;
          } else {
            this.showCatalogs = true;
          }
          break;
        case groupName === 'status':
          this.requestByStatus(settingName);

          break;
        default:
      }
    },

    updateTable() {
      // this.getGroupList[value].table = resp;
      // this.getGroupList[value].showAnimation = false;
    },
  },
  async mounted() {
    this.tasksListElement = this.$refs.list;
    this.taskElements = this.tasksListElement.querySelectorAll('.item-list');

    this.$store.dispatch('userPanel/load');
    this.publicSale = new PublicSale();
  },
  unmounted() {
    this.resetGroups();
  },
};
</script>

<style lang="scss" scoped>
.group-statistic {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}

.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;

  @media (max-width: 1300px) {
    position: absolute;
    top: 1px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: 100%;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #5e82a8;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.6) inset;
  border: 1px solid #383f48;

  color: #fff;
  // text-decoration: underline;

  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  font-size: 20px;

  text-decoration: none;

  &:active {
    box-shadow: 2px 2px 5px #383f48;
  }

  &__icon {
    width: 35px;
    height: 35px;

    margin: 0 0 0 10px;
  }
}

.contain {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // .title__input-panel
  &__input-panel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 100%;
  }

  // .title__input
  &__input {
    width: 100%;
    height: 32px;
    // user-select: none;
    // pointer-events: none;
    margin: 0 0 0 10px;

    border: none;
    cursor: default;

    font-size: 18px;
    padding: 0 5px;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    outline: none;

    background: #c5c5c500;
  }

  // .title__menu
  &__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto;

    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  &__button {
    width: 40px;
    height: 40px;

    margin: 0 5px;
  }

  // .title__icon
  &__icon {
    width: 100%;
    height: 100%;
  }

  &__arrow {
    width: 25px;
    height: 25px;

    transform: rotate(-90deg);
  }
}
.communites {
  display: flex;
  justify-content: center;
  align-items: stretch;

  position: relative;

  width: 100%;
}

.contenter {
  width: calc(100% - 350px);
  @media (max-width: 1300px) {
    width: 100%;
  }
}

.teamplate {
  border: 1px solid #03030325;
  position: relative;
}

.show {
  visibility: visible;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
